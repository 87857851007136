<template>
    <div class="container repair-container">

        <!-- Content -->
        <b-alert
                show
                variant="info">
            <animated-info></animated-info>
            <div class="alert-content">
                If there is anything you need to make Samsung aware of in regards to this <span class="text-no-wrap">In-Warranty</span> repair, you can add a message below.
            </div>
        </b-alert>

        <div class="form-group">
            <label>Add note to samsung <small class="text-muted">(optional)</small></label>
            <textarea class="form-control" rows="6" placeholder="Optional"></textarea>
        </div>

        <!-- Footer -->
        <fixed-footer
                :class="{'active': footerActive}"
                btnIcon="fa-check"
                nextLabel="Complete job"
                nextRoute="repair.repairComplete">
        </fixed-footer>

    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        data() {
            return {
                footerActive: true
            }
        },

        mounted() {
            this.loadDeviceForJob({
                jobId: this.jobId,
                deviceId: this.deviceId,
            }).then(() => {
                this.setCurrentStep({
                    jobId: this.jobId,
                    deviceId: this.deviceId,
                    currentStep: this.$route.name,
                });
            });
        },

        methods: {
            ...mapActions([
                "loadDeviceForJob",
                "setCurrentStep"
            ]),
        }
    }
</script>

<style scoped>

</style>